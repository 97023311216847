import { getBucket } from '@naturalcycles/shared'
import { selectAssignments } from '@src/store/experiment/experiment.slice'
import { useMemo } from 'react'
import { useAppSelector } from './useAppSelector'

/**
 * Returns the bucket for a given experiment id.
 *
 *
 * @param experimentId - The experiment id
 * @returns
 * - null : we're still waiting for experiment to be returned from backend OR to be loaded from localStorage
 * - undefined : we got experiments data, but no bucket was found for provided experimentId
 * - string : experiment was found and bucket is returned
 */
export function useExperimentBucket(experimentId: string): { bucket?: string | null } {
  const assignments = useAppSelector(selectAssignments)

  const bucket = useMemo(() => {
    if (assignments == null) {
      return null
    }

    const assignment = assignments?.[experimentId]
    return assignment ? getBucket(assignment) : undefined
  }, [assignments])

  return { bucket }
}
