import { createContext } from 'react'

export interface CampaignProps {
  code?: string
  ignoreConfirmation?: boolean
  locale?: string
}

export type Campaign = CampaignProps | undefined

export const campaignContext = createContext<Campaign>(undefined)
