import { _assert } from '@naturalcycles/js-lib'
import { getTrafficOrigin } from './trafficOriginUtil'

/**
 * Gets the environment-specific signup URL with an optional
 * path appended to it.
 *
 * @example
 * ```ts
 * const { locale } = useContext(languageContext)
 *
 * const manageAccountUrl = getSignupURL(
 *   `${locale}/account/login`
 * ).toString()
 *
 * const baseUrl = getSignupURL().toString()
 *
 * ncNavigate(shouldLogin ? manageAccountUrl : baseUrl)
 */
export function getSignupURL(path?: string): URL {
  const gatsbySignupUrl = process.env['GATSBY_SIGNUP_URL']
  _assert(gatsbySignupUrl, 'GATSBY_SIGNUP_URL is not defined')

  let fullUrl = new URL(gatsbySignupUrl)
  if (path) {
    fullUrl = new URL(path, fullUrl)
  }

  return fullUrl
}

export function addUTMParamsToUrl(url: URL, customSource: string): void {
  const origin = getTrafficOrigin()
  url.searchParams.set('utm_source_web_medium', origin.utm_medium)
  if (customSource) url.searchParams.set('utm_source_web', customSource)
}

export function getTrackedSignupURL(path: string, customSource: string): URL {
  const url = getSignupURL(path)
  addUTMParamsToUrl(url, customSource)

  return url
}
