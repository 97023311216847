import { pDelay } from '@naturalcycles/js-lib'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Thunk } from '@src/store'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'

export enum PopupStatuses {
  Open = 'Open',
  Closing = 'Closing',
  Closed = 'Closed',
  Disabled = 'Disabled',
}

export interface PopupCampaignImpressions {
  [id: string]: number
}

export interface PopupState {
  status: PopupStatuses
  impressions: PopupCampaignImpressions
}

const initialState: PopupState = {
  status: PopupStatuses.Closed,
  impressions: {},
}

export const slice = createSlice({
  name: 'popup',

  initialState,

  reducers: {
    open(popup, action: PayloadAction<string>): void {
      if (popup.status === PopupStatuses.Disabled) {
        return
      }

      const id = action.payload
      popup.status = PopupStatuses.Open
      if (popup.impressions[id] === undefined) {
        popup.impressions[id] = 1
      } else {
        popup.impressions[id] += 1
      }
    },

    startClosing(popup): void {
      popup.status = PopupStatuses.Closing
    },

    finishClosing(popup): void {
      popup.status = PopupStatuses.Closed
    },

    disable(popup): void {
      popup.status = PopupStatuses.Disabled
    },
  },
})

export function openPopup(id: string): Thunk {
  return async (dispatch, getState) => {
    const state = getState()
    const impressionCount = state.popup.impressions[id] || 0
    if (impressionCount > 0) {
      // This popup campaign has already been delivered to this user so we return
      // early in order to avoid bugging them with duplicate impressions.
      return
    }

    const mobileMenuStatus = selectMobileMenuStatus(state)
    if (mobileMenuStatus === MobileMenuStatuses.Open) {
      // The user is about to navigate away from the page using the mobile menu
      // so we return early in order to avoid wasting a popup impression at a
      // low conversion rate moment when it'll look ugly against the backdrop of
      // the menu and annoy the user by interrupting an interaction they're in
      // the middle of.
      return
    }

    const popupStatus = selectPopupStatus(state)
    if (popupStatus === PopupStatuses.Disabled) {
      // Popups have been disabled for this session for one reason or another
      // so return early to avoid showing one.
      return
    }

    dispatch(slice.actions.open(id))

    const newImpressionCount = getState().popup.impressions[id]
    localStorage.setItem(`<Popup name="${id}" />`, `${newImpressionCount}`)
  }
}

export function closePopup(): Thunk {
  return async dispatch => {
    dispatch(slice.actions.startClosing())
    await pDelay(256)
    dispatch(slice.actions.finishClosing())
  }
}

export function disablePopups(): Thunk {
  return dispatch => {
    dispatch(slice.actions.disable())
  }
}

export function selectPopup({ popup }: { popup: PopupState }): PopupState {
  return popup
}

export const selectPopupStatus = createSelector([selectPopup], (popup): PopupStatuses => {
  return popup.status
})
