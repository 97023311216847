import { Button } from '@src/components/button/Button.component'
import { APP_STORE_URL } from '@src/helpers/env'
import { mixpanelService } from '@src/srv/mixpanel.service'
import type { MouseEvent, ReactNode } from 'react'
import { LocalizedFooterIcon } from '../localized-footer-icon/LocalizedFooterIcon.component'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './AppStoreIcon.module.scss'

interface AppStoreIconProps {
  href?: string
  theme?: string
}

export function AppStoreIcon({
  href = APP_STORE_URL,
  theme = 'official',
}: AppStoreIconProps): ReactNode {
  const elementName = 'app-download-button-ios'

  function handleClick(event: MouseEvent): void {
    event.preventDefault()
    mixpanelService.trackLink(elementName, href)
  }

  return (
    <>
      {theme === 'official' && (
        <NcLink to={href} onClick={handleClick}>
          <LocalizedFooterIcon
            icon="app-store"
            className={styles.AppStoreIcon__image}
            alt="App Store"
            loading="lazy"
          />
        </NcLink>
      )}

      {theme === 'button' && (
        <Button size="flexible" theme="brandPurple" elementName={elementName} href={href}>
          <div className={styles.AppStoreIcon__button}>
            <img
              className={styles.AppStoreIcon__icon}
              src={`/icons/app-store-mini.svg`}
              alt="App Store"
              loading="lazy"
            />
            App Store
          </div>
        </Button>
      )}
    </>
  )
}
