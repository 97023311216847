import { MainMenuItemRenderer } from '@src/components/menu-item-renderer/MenuItemRenderer.component'
import { languageContext } from '@src/context/language.context'
import { filterMenuItems } from '@src/helpers/filterMenuItems'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { DisplayType, MenuItem } from '@src/typings/navigation-menu.d'
import { graphql, useStaticQuery } from 'gatsby'
import type { ReactNode } from 'react'
import { useContext } from 'react'
import styles from './DesktopMenu.module.scss'

export function DesktopMenu(): ReactNode {
  const country = useAppSelector(selectGeolocationCountry)
  const { locale } = useContext(languageContext)

  const data = useStaticQuery(graphql`
    query {
      allDatoCmsMenu {
        edges {
          node {
            id
            _allNavigationItemsLocales {
              locale
              value {
                id
                label
                componentType {
                  __typename
                  ...MenuLinkFragment
                  ...MinorMenuFragment
                  ...SubmenuFragment
                }
                region
                display
                uid
              }
            }
          }
        }
      }
    }
  `)

  const menuData = data.allDatoCmsMenu.edges[0].node._allNavigationItemsLocales
  const filteredMenuItems: MenuItem[] = filterMenuItems(
    menuData,
    locale,
    country,
    DisplayType.Desktop,
  )
  const mainMenuItems = filteredMenuItems.filter(
    item => item.componentType.__typename !== 'DatoCmsMinorMenu',
  )

  return (
    <ul uid="site-desktop-menu" className={styles.DesktopMenu}>
      {mainMenuItems.map(item => (
        <li key={item.id} className={styles.BurgerMenu__item}>
          <MainMenuItemRenderer item={item} />
        </li>
      ))}
    </ul>
  )
}
