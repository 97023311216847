import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Thunk } from '@src/store'

export enum MobileMenuStatuses {
  Open = 'Open',
  Closed = 'Closed',
}

export interface MobileMenuState {
  status: MobileMenuStatuses
}

const initialState: MobileMenuState = {
  status: MobileMenuStatuses.Closed,
}

export const slice = createSlice({
  name: 'mobileMenu',

  initialState,

  reducers: {
    open(mobileMenu: MobileMenuState) {
      mobileMenu.status = MobileMenuStatuses.Open
    },

    close(mobileMenu: MobileMenuState) {
      mobileMenu.status = MobileMenuStatuses.Closed
    },
  },
})

export function openMobileMenu(): Thunk {
  return async dispatch => {
    dispatch(slice.actions.open())
  }
}

export function closeMobileMenu(): Thunk {
  return async dispatch => {
    dispatch(slice.actions.close())
  }
}

export function selectMobileMenu({ mobileMenu }: { mobileMenu: MobileMenuState }): MobileMenuState {
  return mobileMenu
}

export const selectMobileMenuStatus = createSelector(
  [selectMobileMenu],
  (mobileMenu): MobileMenuStatuses => {
    return mobileMenu.status
  },
)
