import { useLocation } from '@reach/router'
import { RegionPicker } from '@src/components/region-picker/RegionPicker.component'
import { NavMenuMobilePlacements } from '@src/helpers/menu'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useNavMenuMobile } from '@src/hooks/useNavMenuMobile'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './MobileMenu.module.scss'

export function MobileMenu(): ReactNode {
  const status = useAppSelector(selectMobileMenuStatus)
  const { pathname } = useLocation()
  const { menu } = useNavMenuMobile(NavMenuMobilePlacements.MobileMenu)
  const { menu: extraLinks } = useNavMenuMobile(NavMenuMobilePlacements.MobileMenuExtra)
  const menuLinkRef = useRef<HTMLLIElement>(null)
  const [keyUser, setKeyUser] = useState(false)

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      setKeyUser(true)
    }
    if (event.code === 'Space' || event.code === 'Enter') {
      const targetElement = document.activeElement as HTMLElement

      if (targetElement?.tagName === 'LI') {
        const clickTarget = targetElement.children[0] as HTMLAnchorElement
        clickTarget.click()
      } else {
        targetElement.click()
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  useEffect(() => {
    if (status === MobileMenuStatuses.Open && menuLinkRef.current !== null && keyUser) {
      menuLinkRef.current.focus()
    }
  }, [status])

  function linkClassName(href: string, extra?: boolean): string {
    return classNames(styles.MobileMenu__link, {
      [styles.MobileMenu__linkActive]: pathname === href,
      [styles.MobileMenu__linkExtra]: extra,
      [styles.MobileMenu__linkExtraActive]: extra && pathname === href,
    })
  }

  const menuClass = useMemo(() => {
    return classNames(styles.MobileMenu, styles.MobileMenu__supportPurple, {
      [styles.MobileMenu__withExtra]: extraLinks ? extraLinks.length > 0 : false,
      [styles.MobileMenu__regionPicker]: pathname.includes('/shop'),
    })
  }, [extraLinks])

  if (status === MobileMenuStatuses.Closed) {
    return <></>
  }

  return (
    <div className={menuClass} uid="site-mobile-menu">
      <ul className={styles.MobileMenu__mainList}>
        {menu?.map((link, i) => (
          <li
            className={styles.MobileMenu__item}
            key={link?.id ?? link.text}
            ref={i === 0 ? menuLinkRef : null}
            tabIndex={1 + i}
          >
            <a
              uid={link.id}
              className={linkClassName(link.href)}
              href={link.href}
              aria-label={link.text === 'FAQs' ? 'Frequently Asked Questions' : link.text}
              {...(link.onClick ? { onClick: link.onClick } : {})}
            >
              {link.text}
            </a>
          </li>
        ))}
      </ul>

      {extraLinks && extraLinks.length > 0 && (
        <>
          <h5 uid="extra-menu-header" className={styles.MobileMenu__extraListTitle}>
            More from Natural Cycles°
          </h5>
          <ul className={styles.MobileMenu__extraList}>
            {extraLinks?.map((link, i) => (
              <li className={styles.MobileMenu__item} key={link.id ?? link.text} tabIndex={7 + i}>
                <a className={linkClassName(link.href, true)} href={link.href} uid={link.id}>
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}

      {pathname.includes('/shop') && (
        <div className={styles.MobileMenu__regionDropDown}>
          <RegionPicker />
        </div>
      )}
    </div>
  )
}
