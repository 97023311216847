import { MenuItem } from 'navigation-menu'
import type { ReactNode } from 'react'
import { MenuDropdown } from '../menu-dropdown/MenuDropdown.component'
import { MenuLibrary } from '../menu-library/MenuLibrary.component'
import { MenuLink } from '../menu-link/MenuLink.component'

interface MainMenuItemRendererProps {
  item: MenuItem
}

export function MainMenuItemRenderer({ item }: MainMenuItemRendererProps): ReactNode {
  const { componentType, label, uid } = item

  if (componentType.__typename === 'DatoCmsMenuLink') {
    return <MenuLink uid={uid} href={componentType.href} label={label} />
  }

  if (componentType.__typename === 'DatoCmsSubmenu') {
    if (componentType.submenu.__typename === 'DatoCmsMenuLibrary') {
      const { button, categories, desktopHeading, desktopContext, desktopButton } =
        componentType.submenu

      return (
        <MenuLibrary
          label={label}
          button={{ cta: button.text, href: button.url }}
          categories={categories}
          desktopHeading={desktopHeading}
          desktopContext={desktopContext}
          desktopButton={desktopButton}
          uid={uid}
        />
      )
    }

    if (componentType.submenu.__typename === 'DatoCmsMenuDropdown') {
      const { dropdownList } = componentType.submenu

      return <MenuDropdown uid={uid} label={label} dropdownList={dropdownList} />
    }
  }
}
