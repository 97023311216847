import { Experiment } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import { languageContext } from '@src/context/language.context'
import { useExperimentBucket } from '@src/hooks/useExperimentBucket'
import { hotjarService } from '@src/srv/hotjar.service'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { useContext, useEffect } from 'react'

/**
 * Returns the experiment bucket or null.
 * Optionally logs events to Mixpanel and Hotjar.
 *
 * @param experiment - The experiment to check the user's bucket against.
 * @param logHotjarEvent - Whether to enable Hotjar tracking.
 * @param logImpression - Whether to log the mixpanel impression or not.
 * @param allowedLocales - An array of allowed locales for the experiment.
 * @returns An array with a single element: `null` if loading, `bucket` test bucket otherwise.
 */
export function useTestStatus(
  experiment: Experiment,
  logHotjarEvent = false,
  logImpression = false,
  allowedLocales?: SupportedLocale[],
): [string | undefined | null] {
  const { bucket } = useExperimentBucket(experiment)
  const { locale } = useContext(languageContext)

  if (allowedLocales && !allowedLocales.includes(locale)) {
    return [null]
  }

  useEffect(() => {
    if (bucket) {
      if (logImpression) {
        mixpanelService.testImpression(experiment, bucket)
        console.log(
          `Impression logged for experiment ${experiment} in group ${bucket} with locale ${locale}`,
        )
      }
      if (logHotjarEvent) {
        hotjarService.sendHotjarEvent(experiment, bucket)
      }
    }
  }, [bucket, experiment, logHotjarEvent, logImpression])

  return [bucket]
}
