import { _objectAssign } from '@naturalcycles/js-lib'
import { ProductState } from '@naturalcycles/shared'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ProductState = {
  items: [],
}

export const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    replace(product: ProductState, action) {
      _objectAssign(product, action.payload)
    },
  },
})
