import { _isNotNullish } from '@naturalcycles/js-lib'
import { Goal } from '@naturalcycles/shared'
import {
  Button,
  ButtonFontSizes,
  ButtonProps,
  ButtonSizes,
  ButtonThemes,
} from '@src/components/button/Button.component'
import { campaignContext } from '@src/context/campaign.context'
import { languageContext } from '@src/context/language.context'
import { addUTMParamsToUrl, getSignupURL } from '@src/helpers/signupUrl'
import { useAppSelector } from '@src/hooks/useAppSelector'
import type { MouseEvent, ReactElement } from 'react'
import { useContext } from 'react'

interface SignupButtonProps {
  id?: string
  uid?: string
  children: any
  elementName?: string
  size?: ButtonSizes
  theme?: ButtonThemes
  underline?: boolean
  code?: string | undefined
  ignoreConfirmation?: boolean | undefined
  ncGoal?: Goal | undefined
  fontSize?: ButtonFontSizes
  ncUtmSetSource?: string
  signupUrlPath?: string
  /**
   * Code that runs on click before redirecting to the signup page.
   * If this function returns false, the redirect will not happen.
   */
  onClick?: ButtonProps['onClick']
}

function getFirstDefinedElement(list: unknown[]): any {
  return list.find(v => v !== undefined)
}

export function SignupButton({
  id,
  uid,
  children,
  elementName = 'SignupButton',
  size = 'regular',
  theme = 'brandPurple',
  underline = false,
  code,
  ignoreConfirmation,
  ncGoal,
  fontSize = ButtonFontSizes.RegularText,
  signupUrlPath,
  ncUtmSetSource,
  onClick: customHandleClick,
}: SignupButtonProps): ReactElement {
  const campaign = useContext(campaignContext)
  const discountCode = useAppSelector(state => state.discountCode.code)
  const windowLoaded = typeof window !== 'undefined'

  const locale = getFirstDefinedElement([
    // The campaign locale has a higher priority than the LanguageContext locale
    // for pages such as /c/plan-offer-uk which whose filename ends in en-us.tsx
    // but which still needs the en-GB locale in its signup links.
    campaign?.locale,

    // Fall back to the general-purpose site-wide LanguageContext locale for
    // 99% of signup buttons.
    useContext(languageContext).locale,
  ])

  const savedDiscountCode = windowLoaded && window.sessionStorage.getItem('discountCode')
  const popupCode = discountCode || savedDiscountCode

  code = getFirstDefinedElement([
    // The code from the props gets the highest priority so that landing pages
    // with multiple codes such as /c/choose-your-offer can specify a different
    // code on one specific signup button.
    code,

    // Fall back to the CampaignContext code for the majority of landing page
    // signup buttons.
    campaign?.code,

    // Last priority is checking for coupon codes stored in the global state or the session storage coming from popups
    popupCode,
  ])

  ignoreConfirmation = getFirstDefinedElement([
    // The value from the props gets the highest priority so that the behaviour
    // of the `ignoreConfirmation` value matches that of `code`.
    ignoreConfirmation,

    // Fall back to the CampaignContext value for the majority of landing page
    // signup buttons.
    campaign?.ignoreConfirmation,
  ])

  const htmlUrl = getSignupURL(signupUrlPath || `${locale}/signup`)

  const reduxReferral = useAppSelector(state => state.discountCode.referral)
  const storedReferral = windowLoaded && window.sessionStorage.getItem('discountReferral')
  const referral = reduxReferral || storedReferral

  const reduxName = useAppSelector(state => state.discountCode.name)
  const storedName = windowLoaded && window.sessionStorage.getItem('discountName')
  const name = reduxName || storedName

  if (typeof code === 'string' && code.length > 0) {
    htmlUrl.pathname += '/secret'
    htmlUrl.searchParams.set('code', code)

    // If the user has arrived with a referral id we pass that on to signup
    // Otherwise if they come from an influencer or referral we set
    // ignoreconfirmation to be true
    const fromReferral = typeof referral === 'string' && referral.length > 1
    const fromInfluencer = typeof name === 'string' && name.length > 1

    if (fromReferral) {
      htmlUrl.searchParams.set('referral', referral)
    }

    // We only set an `ignoreConfirmation` searchParam for /signup/secret
    // links, referrals and influencer page traffic to prevent them from
    // seeing the confirmation page in signup
    if (fromReferral || fromInfluencer) {
      htmlUrl.searchParams.set('ignoreConfirmation', 'true')
    } else if (typeof ignoreConfirmation === 'boolean') {
      htmlUrl.searchParams.set('ignoreConfirmation', ignoreConfirmation.toString())
    }
  }

  // for users from the pricing page who have selected their plan
  // and will be taken directly to corresponding payment page
  if (ncGoal) {
    htmlUrl.searchParams.set('mode', ncGoal.toString())
  }

  function isPromise(value: any): value is Promise<any> {
    return value && typeof value.then === 'function'
  }

  const href = htmlUrl.toString()

  function handleClick(event: MouseEvent): boolean | void | Promise<void> {
    event.preventDefault()

    function afterCustom(): boolean {
      const clickUrl = new URL(href)
      if (window.Cookiebot?.hasResponse) {
        clickUrl.searchParams.set('cp', String(window.Cookiebot?.consent?.preferences))
        clickUrl.searchParams.set('cs', String(window.Cookiebot?.consent?.statistics))
        clickUrl.searchParams.set('cm', String(window.Cookiebot?.consent?.marketing))
      }

      addUTMParamsToUrl(clickUrl, ncUtmSetSource || 'signup_button')
      globalThis.location.assign(clickUrl.toString())
      return false
    }

    if (isPromise(customHandleClick)) {
      return customHandleClick.then(afterCustom).then(() => {})
    }

    return customHandleClick?.(event) === false ? false : afterCustom()
  }

  const button: ButtonProps = {
    elementName,
    fontSize,
    href,
    onClick: handleClick,
    size,
    theme,
    underline,
    id,
    uid,
  }
  return (
    <>
      {locale && _isNotNullish(children) && (
        // Signup button should be ClientOnly to avoid hydration issues, but we need it not to be for
        // tests right now. Todo: Move the signup button tests to be e2e tests.
        <Button {...button} onClick={handleClick} suppressHydrationWarning={true}>
          {children}
        </Button>
      )}
      {/* Placeholder for CLS */}
      {!locale && <div style={{ height: '46px', width: '100%' }} />}
    </>
  )
}
