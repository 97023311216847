import { _stringEnumKey } from '@naturalcycles/js-lib'
import { ncNavigate } from '@src/helpers/nc-navigate'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import {
  RegionDescriptor,
  RegionKey,
  regionKeys,
  RegionSlug,
  ShippingLocation,
  StorageRegion,
} from '@src/shop/cnst/shopify.cnst'
import {
  clearCart,
  selectShopifyShippingLocation,
  setExpectedLocation,
  setShippingLocation,
} from '@src/shop/store/shopify.slice'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import styles from './RegionPicker.module.scss'

export function RegionPicker(): ReactNode {
  const dispatch = useAppDispatch()

  function getTextForShippingLocation(region?: ShippingLocation): RegionDescriptor {
    if (!region) return RegionDescriptor.ROW
    const regionKeyOrBlocked = _stringEnumKey(ShippingLocation, region)
    const key = regionKeyOrBlocked === 'Blocked' ? 'ROW' : regionKeyOrBlocked
    return RegionDescriptor[key]
  }

  function getRegionUid(region: RegionKey): string {
    const uidPrefix = 'region-option-'
    const uidSuffix = StorageRegion[region]
    return uidPrefix.concat(uidSuffix)
  }

  const selectedRegion = useAppSelector(selectShopifyShippingLocation)
  const [regionText, setRegionText] = useState(getTextForShippingLocation(selectedRegion))

  useEffect(() => {
    dispatch(clearCart())
    setRegionText(getTextForShippingLocation(selectedRegion))
  }, [selectedRegion])

  const [expanded, setExpanded] = useState(false)

  function toggleRegions(): void {
    setExpanded(!expanded)
  }

  return (
    <div className={styles.RegionPicker} uid="region-picker">
      <button
        aria-label="select a new region"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        className={styles.RegionPicker__button}
        onClick={toggleRegions}
      >
        <div
          className={styles.RegionPicker__pipe}
          dangerouslySetInnerHTML={{ __html: regionText }}
          style={{ display: `inline-block` }}
        />

        <svg
          className={expanded ? styles.RegionPicker__arrowUp : styles.RegionPicker__arrowDown}
          aria-hidden={true}
          width="13"
          height="9"
          viewBox="0 0 13 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0655 7.42161L6.81799 2.17407L1.51251 7.36117"
            stroke="#72035D"
            strokeWidth="2"
          />
        </svg>
      </button>
      <ul
        role="listbox"
        className={expanded ? styles.RegionPicker__dropdown : styles.RegionPicker__hidden}
      >
        {regionKeys.map(region => (
          <li
            key={region}
            role="option"
            aria-selected={selectedRegion === ShippingLocation[region]}
            uid={getRegionUid(region)}
            className={`${styles.RegionPicker__item} ${
              selectedRegion === ShippingLocation[region] && styles.RegionPicker__itemSelected
            }`}
          >
            <a
              href={`/shop/${RegionSlug[region]}`}
              className={styles.RegionPicker__link}
              onClick={e => {
                e.preventDefault()
                dispatch(setExpectedLocation(ShippingLocation[region]))
                dispatch(setShippingLocation(ShippingLocation[region]))
                setExpanded(false)
                ncNavigate(`/shop/${RegionSlug[region]}`)
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: getTextForShippingLocation(ShippingLocation[region]),
                }}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
