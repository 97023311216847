import type { PropsWithChildren } from 'react'
import { ReactNode, useEffect, useState } from 'react'

interface ClientProps extends PropsWithChildren {
  placeHolder?: ReactNode
}

export function ClientOnly({ children, placeHolder }: ClientProps): ReactNode {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return <>{hasMounted ? children : placeHolder}</>
}
