import { translationContext } from '@src/context/translation.context'
import { useContext } from 'react'

export function useTranslation(): (key: string) => string {
  const translations: { [key: string]: string } = useContext(translationContext)

  return (key: string): string => {
    return translations[key]!
  }
}
