import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { NcImage } from '@src/components/nc-image/NcImage.component'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { AddToCartButton } from '@src/shop/components/add-to-cart-button/AddToCartButton.component'
import { shopifyService } from '@src/shop/srv/shopify.service'
import { AddToCartSources, ShopifyProduct } from '@src/shop/store/shopify.model'
import { selectShopifyShippingLocation } from '@src/shop/store/shopify.slice'
import { graphql, useStaticQuery } from 'gatsby'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import styles from './FeaturedProductsMiniCart.module.scss'

interface FeaturedProductsProps {
  currentProducts: (string | undefined)[]
  showHeadline?: boolean
  customHeadline?: string
}

export function FeaturedProductsMiniCart({
  currentProducts,
  showHeadline = true,
  customHeadline = 'Recommended for You',
}: FeaturedProductsProps): ReactNode {
  const { allShopifyProduct } = useStaticQuery(graphql`
    {
      allShopifyProduct(sort: { rank: ASC }, filter: { handle: { ne: "10-pregnancy-tests" } }) {
        edges {
          node {
            title
            handle
            tags
            images {
              originalSrc
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, placeholder: NONE)
                }
              }
            }
            variants {
              usd {
                price {
                  amount
                  currencyCode
                }
              }
              sek {
                price {
                  amount
                  currencyCode
                }
              }
              gbp {
                price {
                  amount
                  currencyCode
                }
              }
              eur {
                price {
                  amount
                  currencyCode
                }
              }
              id
            }
            packageQuantity
            brandLogo
            shortDescription
          }
        }
      }
    }
  `)

  const mobileSlider = {
    className: styles.FeaturedProducts__productContainer,
    arrows: true,
    dots: false, // Enable dots for navigation
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 2.5, // Default for larger screens
    slidesToScroll: 2, // Default for larger screens
    responsive: [
      {
        breakpoint: 768, // For screens 768px or less
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1, // Scroll 1 slide at a time
          dots: true, // Show dots on mobile
          arrows: false, // Hide arrows for mobile if desired
        },
      },
    ],
  }

  const shippingLocation = useAppSelector(selectShopifyShippingLocation)

  const allProducts = allShopifyProduct.edges.filter((product: { node: ShopifyProduct }) => {
    return (
      !currentProducts.includes(product.node.title) &&
      shippingLocation &&
      product.node.tags.includes(shippingLocation)
    )
  })

  const featuredProducts: { node: ShopifyProduct }[] = allProducts.slice(0, 6)

  const currentCurrency = useAppSelector(state => state.shopify.currency)
  const [currencyCode, setCurrencyCode] = useState('USD')

  useEffect(() => {
    setCurrencyCode(currentCurrency)
  }, [shippingLocation])

  const products = featuredProducts.map(product => {
    const { handle, title, images } = product.node
    return (
      <a href={`/shop/${handle}`} key={title} className={styles.FeaturedProducts__product}>
        <div className={styles.FeaturedProducts__desktopProductContainer}>
          <NcImage
            alt={product.node.title}
            gatsbyImageData={(images[0] as any).localFile.childImageSharp.gatsbyImageData}
            loading="eager"
          />
          {product.node.brandLogo && (
            <p className={styles.FeaturedProducts__productBrand}>
              <img src={product.node.brandLogo} alt="logo name" width="100" />
            </p>
          )}
          <p className={styles.FeaturedProducts__productTitle}> {product.node.title} </p>
          {product.node.packageQuantity && (
            <p className={styles.FeaturedProducts__productQuantity}>
              {' '}
              {product.node.packageQuantity}{' '}
            </p>
          )}
          <div className={styles.FeaturedProducts__productAdd}>
            <AddToCartButton
              product={product.node}
              quantity={1}
              size="small"
              source={AddToCartSources.FeaturedProduct}
              ariaLabel={`add ${product.node.title} to cart`}
              price={new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: shopifyService.findPrice(product.node, currencyCode, handle).currencyCode,
              }).format(
                Number.parseFloat(shopifyService.findPrice(product.node, currencyCode).amount),
              )}
            />
          </div>
        </div>
      </a>
    )
  })

  return (
    <div uid="featured-products" className={styles.FeaturedProducts}>
      {showHeadline && <h4 className={styles.FeaturedProducts__heading}>{customHeadline}</h4>}
      <ClientOnly>
        <Slider {...mobileSlider}>{products}</Slider>
      </ClientOnly>
    </div>
  )
}
