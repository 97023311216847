import { clientSideRedirects } from '@src/cnst/redirects.cnst'

export function getRedirectOrUndefined(currentURL: URL | Location): URL | undefined {
  const matchingRedirect = clientSideRedirects.find(
    redirect =>
      // We also check without the trailing slash
      redirect.from.test(currentURL.href) || redirect.from.test(currentURL.href.replace(/\/$/, '')),
  )
  if (!matchingRedirect) {
    return
  }

  try {
    const newUrl = new URL(currentURL.href.replace(matchingRedirect.from, matchingRedirect.to))
    return newUrl
  } catch {
    return
  }
}

export function redirectIfMatched(currentURL: URL | Location): boolean {
  const redirect = getRedirectOrUndefined(currentURL)
  if (!redirect) {
    return false
  }
  // Gatsby's navigate isn't available yet in onClientEntry
  globalThis.location.href = redirect.toString()
  return true
}
