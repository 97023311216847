export const domainAliases = [
  'naturalcycles.biz',
  'naturalcycles.de',
  'naturalcycles.co',
  'naturalcycles.dk',
  'naturalcycles.es',
  'naturalcycles.fr',
  'naturalcycles.info',
  'naturalcycles.me',
  'naturalcycles.net',
  'naturalcycles.com.br',
]

export const shopDomainAliases = ['shop.naturalcycles.com', 'shop.naturalcycles.se']

// shop eu redirects
export const euCountries = [
  'ad',
  'at',
  'ax',
  'be',
  'cz',
  'ch',
  'cy',
  'de',
  'dk',
  'ee',
  'es',
  'fi',
  'fo',
  'fr',
  'gf',
  'gr',
  'ie',
  'is',
  'it',
  'li',
  'lt',
  'lu',
  'lv',
  'mc',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'rs',
  'si',
  'sk',
  'sm',
  'ua',
  'va',
]

export const removedQuizSlugs = [
  'quiz',
  'plan-quiz',
  'your-birth-control-quiz',
  'your-plan-pregnancy-quiz',
]

export const usOnlyPages = [
  'research-library',
  'announcements',
  'other/legal',
  'press-hub',
  'c',
  'samsung',
  'press-highlights',
]

/**
 * Client-side redirects, ordered by priority
 */
export const clientSideRedirects: ClientSideRedirect[] = [
  // Some pages are only available in English
  ...usOnlyPages.map(page => ({
    from: new RegExp(`^https://(?:www\\.)?naturalcycles\\.se/${page}(/.*)?$`),
    to: `https://www.naturalcycles.com/${page}$1`,
  })),
  // .se/shop/... -> .com/shop/... (we don't localize shop item pages)
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se\/shop\/(.+)/,
    to: 'https://www.naturalcycles.com/shop/$1',
  },
  // .se/shop -> .com/shop/se
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se\/shop/,
    to: 'https://www.naturalcycles.com/shop/se',
  },
  // .se users to .com/sv
  {
    from: /^https:\/\/(?:www\.)?naturalcycles\.se(\/.*)?/,
    to: 'https://www.naturalcycles.com/sv$1',
  },
]

interface ClientSideRedirect {
  from: RegExp
  /**
   * Can include regex groups from the `from` regex
   */
  to: string
}
