import { mixpanelService } from '@src/srv/mixpanel.service'
import { graphql } from 'gatsby'
import { memo } from 'react'
import styles from './MenuLink.module.scss'

interface MenuLinkProps {
  href: string
  label: string
  uid: string
}

export const MenuLink = memo(({ href, label, uid }: MenuLinkProps) => {
  return (
    <a
      uid={uid}
      href={href}
      className={styles.MenuLink}
      onClick={(): void => {
        mixpanelService.trackClick(uid)
      }}
    >
      {label}
    </a>
  )
})

export const menuLinkFragment = graphql`
  fragment MenuLinkFragment on DatoCmsMenuLink {
    id
    href
  }
`
