import {
  Button,
  ButtonFocusAfterClick,
  ButtonProps,
  ButtonSizes,
} from '@src/components/button/Button.component'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { AddToCartSources, ShopifyProduct } from '@src/shop/store/shopify.model'
import { addToCart } from '@src/shop/store/shopify.slice'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import styles from './AddToCartButton.module.scss'

interface AddToCartButtonProps {
  product: ShopifyProduct
  quantity: number
  size: ButtonSizes
  source: AddToCartSources
  disabled?: boolean
  ariaLabel?: string
  price?: string
  sellingPlanId?: string
  isSubscription?: boolean
}

export function AddToCartButton({
  product,
  quantity,
  source,
  size,
  disabled = false,
  ariaLabel,
  price,
  sellingPlanId,
  isSubscription,
}: AddToCartButtonProps): ReactNode {
  const dispatch = useAppDispatch()
  const onClick = useCallback(() => {
    dispatch(addToCart(product, quantity, source, sellingPlanId, isSubscription))
  }, [product, quantity, source, dispatch, sellingPlanId, isSubscription])

  const button: ButtonProps = {
    size,
    theme: disabled ? 'inactiveBrandPurple' : 'brandPurple',
    onClick: disabled ? () => {} : onClick,
    focusAfterClick: ButtonFocusAfterClick.Blur,
    ariaLabel,
    shop: true,
  }

  return (
    <Button {...button} shop>
      <ClientOnly>
        <div className={styles.AddToCartButton__text}>
          Add to Cart <span>|</span> {price}
        </div>
      </ClientOnly>
    </Button>
  )
}
