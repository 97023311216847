import { useLocation } from '@reach/router'
import { HeaderThemes } from '@src/components/header/Header.component'
import { Link } from '@src/components/link/Link.component'
import { languageContext } from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useNavMenuDesktop } from '@src/hooks/useNavMenuDesktop'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import classNames from 'classnames'
import { ReactNode, useContext, useMemo } from 'react'
import { ClientOnly } from '../client-only/ClientOnly.component'
import styles from './DesktopMenu.module.scss'

interface DesktopMenuProps {
  theme: HeaderThemes
}

export function DesktopMenu({ theme }: DesktopMenuProps): ReactNode {
  const { pathname } = useLocation()
  const { menu } = useNavMenuDesktop()
  const { locale } = useContext(languageContext)
  const menuClass = classNames(styles.DesktopMenu, styles[`DesktopMenu__theme${theme}`], {
    [styles.DesktopMenu___sv]: locale === 'sv-SE',
  })
  const country = useAppSelector(selectGeolocationCountry)

  const memoizedMenu = useMemo(() => {
    return menu
  }, [country, menu])

  return (
    <ClientOnly>
      <ul className={menuClass} uid="site-desktop-menu">
        {memoizedMenu?.map(link => {
          const itemClass = classNames(styles.DesktopMenu__item)
          const linkClass = classNames(styles.DesktopMenu__link, {
            [styles.DesktopMenu__activeLink]: pathname === link.href,
          })

          return (
            <li className={itemClass} key={link.id || link.href}>
              <Link
                uid={link.id}
                className={linkClass}
                href={link.href}
                ariaLabel={link.text === 'FAQs' ? 'Frequently Asked Questions' : ''}
                {...(link.onClick ? { onClick: link.onClick } : {})}
              >
                {link.text}
              </Link>
            </li>
          )
        })}
      </ul>
    </ClientOnly>
  )
}
