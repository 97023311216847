import { _omit } from '@naturalcycles/js-lib'
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image'
import type { DetailedHTMLProps, ImgHTMLAttributes, ReactNode } from 'react'
import { Image, ImagePropTypes, ResponsiveImageType } from 'react-datocms'

type ImageElProps = ImagePropTypes &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

interface BaseNcImageProps extends Omit<ImageElProps, 'data'> {
  loading?: 'lazy' | 'eager'
  // GatsbyImageProps has alt required, but we can't guarantee that it's set in dato
  alt?: string
}

interface GatsbyNcImageProps extends BaseNcImageProps {
  gatsbyImageData: IGatsbyImageData
}

interface DatoNcImageProps extends BaseNcImageProps {
  responsiveImage: ResponsiveImageType
}

export type NcImageProps = GatsbyNcImageProps | DatoNcImageProps

/**
 * A wrapper around GatsbyImage that sets values for the `decoding` and `fetchpriority` props'
 * based on the `loading` prop.
 */
export function NcImage({ loading = 'lazy', ...props }: NcImageProps): ReactNode {
  if (!props.alt && !props['aria-hidden'] && process.env['GATSBY_ENV'] !== 'production') {
    console.warn(
      'NcImage: `alt` prop is empty. If this is a decorative image, pass `aria-hidden` prop instead of `alt`.',
    )
  }

  if ('responsiveImage' in props) {
    const imageProps: ImageElProps = {
      ...props,
      decoding: props.decoding || loading === 'eager' ? 'sync' : 'async',
      fetchPriority: props.fetchPriority || loading === 'eager' ? 'high' : 'low',
      loading,
      // Gatsby image will throw on empty alt, we want to warn instead to avoid user facing errors
      alt: props.alt || '',
      data: props.responsiveImage,
    }

    return <Image {...imageProps} />
  }

  const imageProps: GatsbyImageProps = {
    ..._omit(props, ['gatsbyImageData']),
    decoding: props.decoding || loading === 'eager' ? 'sync' : 'async',
    fetchpriority: props.fetchPriority || loading === 'eager' ? 'high' : 'low',
    loading,
    // Gatsby image will throw on empty alt, we want to warn instead to avoid user facing errors
    alt: props.alt || '',
    image: props.gatsbyImageData,
  }

  return <GatsbyImage {...imageProps} />
}
