import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { Thunk } from '@src/store'

export interface DiscountCodeState {
  code: string
  /**
   * A string representing the percentage of the discount.
   * Could be "20% off".
   * // TODO: Change to number and handle making the percentage string in the UI.
   */
  percentage: string
  name: string
  referral: string
  /**
   * If true, the discount is represented as an amount, not a percentage.
   */
  asAmount: boolean
  /**
   * If true and asAmount is true, the calculated discount amount will include the
   * price of the thermometer.
   */
  includesThermometer: boolean
}

const initialState: DiscountCodeState = {
  code: '',
  percentage: '',
  name: '',
  referral: '',
  asAmount: false,
  includesThermometer: false,
}

export const slice = createSlice({
  name: 'discountCode',
  initialState,
  reducers: {
    setDiscountCode(state: DiscountCodeState, action: PayloadAction<string>) {
      state.code = action.payload
    },
    setDiscountPercentage(state: DiscountCodeState, action: PayloadAction<string>) {
      state.percentage = action.payload
    },
    setDiscountName(state: DiscountCodeState, action: PayloadAction<string>) {
      state.name = action.payload
    },
    setDiscountReferral(state: DiscountCodeState, action: PayloadAction<string>) {
      state.referral = action.payload
    },
    setDiscountAsAmount(state: DiscountCodeState, action: PayloadAction<boolean>) {
      state.asAmount = action.payload
    },
    setIncludesThermometer(state: DiscountCodeState, action: PayloadAction<boolean>) {
      state.includesThermometer = action.payload
    },
  },
})

export function setDiscountCode(code: string, source: string): Thunk {
  return dispatch => {
    dispatch(slice.actions.setDiscountCode(code))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountCode', code)
    }

    mixpanelService.track('SaveDiscountCode', { code, source })
  }
}

export function setDiscountPercentage(percentage: string): Thunk {
  return dispatch => {
    dispatch(slice.actions.setDiscountPercentage(percentage))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountPercentage', percentage)
    }
  }
}

export function setDiscountName(name: string, decode = false): Thunk {
  return dispatch => {
    let savedName = name

    if (decode) {
      savedName = window.atob(name)
    }

    dispatch(slice.actions.setDiscountName(savedName))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountName', savedName)
    }
  }
}

export function setDiscountReferral(referral: string): Thunk {
  return dispatch => {
    dispatch(slice.actions.setDiscountReferral(referral))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountReferral', referral)
    }
  }
}

export function setDiscountAsAmount(asAmount: boolean): Thunk {
  return dispatch => {
    dispatch(slice.actions.setDiscountAsAmount(asAmount))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('discountAsAmount', asAmount.toString())
    }
  }
}

export function setIncludesThermometer(includesThermometer: boolean): Thunk {
  return dispatch => {
    dispatch(slice.actions.setIncludesThermometer(includesThermometer))
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('includesThermometer', includesThermometer.toString())
    }
  }
}
