import { SupportedLocale } from '@src/cnst/translations.cnst'
import { languageContext } from '@src/context/language.context'
import { getSignupURL } from '@src/helpers/signupUrl'
import { ReactNode, useContext } from 'react'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './JanuaryBanner.module.scss'

export interface JanuaryBannerProps {}

export function JanuaryBanner({}: JanuaryBannerProps): ReactNode {
  const { locale } = useContext(languageContext)
  const signupUrl = getSignupURL(locale)

  const localeToContent: Partial<Record<SupportedLocale, JanuaryBannerContent>> = {
    [SupportedLocale.enUS]: {
      exclusiveOffer: 'Exclusive offer:',
      buyNaturalCycles: 'Buy Natural Cycles° and get up to',
      discountAmount: '$200',
      offOnOura: 'off MSRP on Oura Ring Gen3',
    },
    [SupportedLocale.svSE]: {
      exclusiveOffer: 'Exklusivt erbjudande:',
      buyNaturalCycles: 'Köp Natural Cycles° och få upp till',
      discountAmount: '210 €',
      offOnOura: 'rabatt på Oura Ring Gen3',
    },
    [SupportedLocale.esUS]: {
      exclusiveOffer: 'Oferta exclusiva:',
      buyNaturalCycles: 'Compra Natural Cycles° y consigue un descuento de hasta ',
      discountAmount: '200 $',
      offOnOura: 'en Oura Ring Gen3',
    },
    [SupportedLocale.ptBR]: {
      exclusiveOffer: 'Oferta exclusiva:',
      buyNaturalCycles: 'Compre o Natural Cycles° e ganhe até',
      discountAmount: '$200',
      offOnOura: 'de desconto no Oura Ring Gen3',
    },
  }

  const content = localeToContent[locale] || localeToContent[SupportedLocale.enUS]!

  return (
    <NcLink className={styles.JanuaryBanner} to={signupUrl.toString()} uid="january-banner">
      <div className={styles.JanuaryBanner__container}>
        <span className={styles.JanuaryBanner__text}>
          <strong>{content.exclusiveOffer} </strong>
          <u>
            {content.buyNaturalCycles}{' '}
            <strong>
              {content.discountAmount.replace(' ', '\u00A0')}&nbsp;
              {content.offOnOura.replace(' ', '\u00A0')}
            </strong>
          </u>
        </span>
      </div>
    </NcLink>
  )
}

interface JanuaryBannerContent {
  exclusiveOffer: string
  buyNaturalCycles: string
  discountAmount: string
  offOnOura: string
}
