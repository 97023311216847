import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Thunk } from '@src/store'

export interface pathnameState {
  pathname: string
}

const initialState: pathnameState = {
  pathname: '',
}

export const slice = createSlice({
  name: 'pathname',

  initialState,

  reducers: {
    setPathname(pathname: pathnameState, action: PayloadAction<string>) {
      pathname.pathname = action.payload
    },
  },
})

export function setPathname(pathname: string): Thunk {
  return dispatch => {
    dispatch(slice.actions.setPathname(pathname))
  }
}
