import { languageContext } from '@src/context/language.context'
import { useContext } from 'react'

const localePrefixes: { [locale: string]: string } = {
  'en-US': '',
  'es-US': '/es',
  'pt-BR': '/pt-br',
  'sv-SE': '/sv',
  'en-CA': '/ca',
}

export function useLocalePrefix(pathname: string): string {
  const { locale } = useContext(languageContext)
  return localePrefixes[locale] + pathname
}
