import { Promisable } from '@naturalcycles/js-lib'
import { addUtmParamsToLink, isExternalUrl, ncNavigate } from '@src/helpers/nc-navigate'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { GatsbyLinkProps } from 'gatsby'
import { MouseEvent as ReactMouseEvent, ReactNode } from 'react'

export interface NcLinkProps<TState> extends GatsbyLinkProps<TState> {
  onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => Promisable<void>
}

export function NcLink<T = any>(props: NcLinkProps<T>): ReactNode {
  const { to, children, state, replace, uid, ...rest } = props
  const isExternal = isExternalUrl(to)

  async function internalOnClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): Promise<void> {
    e.preventDefault()

    if (uid) {
      mixpanelService.trackClick(uid)
    }

    await props.onClick?.(e)
    ncNavigate(to, typeof to === 'string' ? { state, replace } : undefined)
  }

  return (
    <a
      href={addUtmParamsToLink(to)}
      rel={isExternal ? 'noreferrer' : undefined}
      target={isExternal ? '_blank' : undefined}
      {...rest}
      uid={uid}
      onClick={!isExternal ? internalOnClick : undefined}
      // We expect the href not to match the server sometimes as it can depend on the window.location state
      suppressHydrationWarning
    >
      {children}
    </a>
  )
}
