import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import type { ReactNode } from 'react'

export type NcImageProps = {
  loading?: GatsbyImageProps['loading']
  // GatsbyImageProps has alt required, but we can't guarantee that it's set in dato
  alt?: string
} & Omit<GatsbyImageProps, 'loading' | 'alt'>

/**
 * A wrapper around GatsbyImage that sets values for the `decoding` and `fetchpriority` props'
 * based on the `loading` prop.
 */
export function NcImage({ loading = 'lazy', ...props }: NcImageProps): ReactNode {
  const gatsbyProps: GatsbyImageProps = {
    ...props,
    decoding: props.decoding || loading === 'eager' ? 'sync' : 'async',
    fetchpriority: props.fetchpriority || loading === 'eager' ? 'high' : 'low',
    loading,
    // Gatsby image will throw on empty alt, we want to warn instead to avoid user facing errors
    alt: props.alt || '',
  }

  if (!props.alt && !props['aria-hidden'] && process.env['GATSBY_ENV'] !== 'production') {
    console.warn(
      'NcImage: `alt` prop is empty. If this is a decorative image, pass `aria-hidden` prop instead of `alt`.',
    )
  }

  return <GatsbyImage {...gatsbyProps} />
}
