import { TouchEvent } from 'react'

export function checkRight(clientX: number): boolean {
  return clientX > window.innerWidth - 40
}

export function checkLeft(clientX: number): boolean {
  return clientX < 40
}

export const redDotSequence: ((clientX: number) => boolean)[] = [
  checkLeft,
  checkRight,
  checkLeft,
  checkRight,
  checkLeft,
  checkRight,
]

export function redDotUnlockSequence(
  event: TouchEvent<HTMLElement>,
  [sequenceIndex, setSequenceIndex]: [number, React.Dispatch<React.SetStateAction<number>>],
  toggleRedDot: () => void,
): void {
  const clientX = event.touches[0]?.clientX
  if (!clientX) {
    setSequenceIndex(() => 0)
    return
  }

  if (redDotSequence[sequenceIndex]!(clientX)) {
    setSequenceIndex(prev => prev + 1)
  } else {
    setSequenceIndex(0)
    return
  }

  if (sequenceIndex === redDotSequence.length - 1) {
    toggleRedDot()
    setSequenceIndex(0)
  }
}
