import { isClientSide } from '@naturalcycles/js-lib'
import { useEffect, useState } from 'react'

export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: null,
    height: null,
  })

  useEffect(() => {
    if (!isClientSide()) {
      setWindowDimensions({ width: null, height: null })
    } else {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    function handleResize(): void {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    if (!isClientSide()) return
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

interface WindowDimensions {
  width: number | null
  height: number | null
}
