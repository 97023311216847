import { Button } from '@src/components/button/Button.component'
import { PLAY_STORE_URL } from '@src/helpers/env'
import { mixpanelService } from '@src/srv/mixpanel.service'
import type { MouseEvent, ReactNode } from 'react'
import { LocalizedFooterIcon } from '../localized-footer-icon/LocalizedFooterIcon.component'
import styles from './GooglePlayIcon.module.scss'

interface GooglePlayIconProps {
  href?: string
  theme?: string
}

export function GooglePlayIcon({
  href = PLAY_STORE_URL,
  theme = 'official',
}: GooglePlayIconProps): ReactNode {
  const elementName = 'app-download-button-android'

  function handleClick(event: MouseEvent): void {
    event.preventDefault()
    mixpanelService.trackLink(elementName, href)
  }

  return (
    <>
      {theme === 'official' && (
        <a className={styles.GooglePlayIcon} href={href} onClick={handleClick}>
          <LocalizedFooterIcon
            icon="play-store"
            className={styles.GooglePlayIcon__image}
            alt="Play Store"
            loading="lazy"
          />
        </a>
      )}

      {theme === 'button' && (
        <Button size="flexible" theme="brandPurple" elementName={elementName} href={href}>
          <div className={styles.GooglePlayIcon__button}>
            <img
              className={styles.GooglePlayIcon__icon}
              src={`/icons/play-store-mini.svg`}
              alt="Play Store"
              loading="lazy"
            />
            Google Play
          </div>
        </Button>
      )}
    </>
  )
}
