import { useAppSelector } from '@src/hooks/useAppSelector'
import { ProductFrequencyControlOption } from '@src/shop/components/product-frequency-control-option/ProductFrequencyControlOption.component'
import { ProductFrequencySelect } from '@src/shop/components/product-frequency-select/ProductFrequencySelect.component'
import { ProductOptionSource } from '@src/shop/components/product-options/ProductOptions.component'
import { shopifyService } from '@src/shop/srv/shopify.service'
import { PersistedSellingPlan, ShopifyPrice, ShopifyProduct } from '@src/shop/store/shopify.model'
import type { ChangeEvent, ReactNode } from 'react'
import { useState } from 'react'
import styles from './ProductFrequencyControl.module.scss'

export interface ProductFrequencyControlProps {
  product: ShopifyProduct
  source: ProductOptionSource
  price: ShopifyPrice['price']
  legend: string
  onSellingPlanChange?: (sellingPlan: PersistedSellingPlan | null) => void
  onIsSubscriptionChange?: (isSubscription: boolean) => void
  initialSellingPlanId?: string
}

export function ProductFrequencyControl({
  product,
  price,
  legend,
  source,
  initialSellingPlanId,
  onSellingPlanChange,
  onIsSubscriptionChange,
}: ProductFrequencyControlProps): ReactNode {
  const isCart = [ProductOptionSource.Cart, ProductOptionSource.MiniCart].includes(source)
  const cart = useAppSelector(state => state.shopify.cart)
  const variant = product.variants[0]
  const lineItem = variant ? cart[variant.id] : null

  const initialSellingPlan = isCart
    ? product.sellingPlans?.find(sp => sp.selling_plan_id === lineItem?.sellingPlanId)
    : product.sellingPlans?.find(sp => sp.selling_plan_id === initialSellingPlanId)

  const [sellingPlan, setSellingPlan] = useState<PersistedSellingPlan | null>(
    initialSellingPlan || null,
  )

  const [isSubscription, setIsSubscription] = useState<boolean>(!!initialSellingPlan)

  const uiSellingPlan = sellingPlan || product.sellingPlans?.[0]
  const discountedPriceAmount = shopifyService.applySellingPlanDiscount(
    product,
    price.amount,
    uiSellingPlan?.selling_plan_id,
  )

  function handleSellingPlanChange(sellingPlan: PersistedSellingPlan | null): void {
    setSellingPlan(sellingPlan)
    onSellingPlanChange?.(sellingPlan)
  }

  function handleFrequencyChange(e: ChangeEvent<HTMLFieldSetElement>): void {
    const value = (e.target as any).value
    const isSubscription = value === 'Subscribe'
    setIsSubscription(isSubscription)
    onIsSubscriptionChange?.(isSubscription)
  }

  return (
    <fieldset
      className={styles.ProductFrequencyControl}
      // We use the label as the value in the radio buttons
      onChange={handleFrequencyChange}
      role="radiogroup"
    >
      <legend className={styles.ProductFrequencyControl__legend}>{legend}</legend>
      <ProductFrequencyControlOption
        tags={[]}
        source={source}
        price={price}
        label="One-time purchase"
        isSelected={!isSubscription}
        preselected={!isSubscription}
      />
      <ProductFrequencyControlOption
        tags={[
          ...(uiSellingPlan?.pricing_policy_fixed_adjustment_value
            ? [`-${uiSellingPlan.pricing_policy_fixed_adjustment_value}%`]
            : []),
          'free shipping',
        ]}
        source={source}
        price={{
          amount: discountedPriceAmount,
          currencyCode: price.currencyCode,
        }}
        label="Subscribe"
        isSelected={isSubscription}
        preselected={isSubscription}
      >
        {isSubscription && (
          <ProductFrequencySelect
            sellingPlans={product.sellingPlans || []}
            initialSellingPlanId={initialSellingPlan?.selling_plan_id}
            onSellingPlanChange={handleSellingPlanChange}
          />
        )}
      </ProductFrequencyControlOption>
    </fieldset>
  )
}
