import { loadHotjar } from '@naturalcycles/js-lib'
import { sentry } from './sentry.service'

declare global {
  interface Window {
    hj?: (...args: any[]) => void
  }
}

// https://help.hotjar.com/hc/en-us/articles/4405109971095 Hotjar API
class HotjarService {
  init(): void {
    if (process.env['GATSBY_ENV'] !== 'production') {
      return
    }

    if (!window.Cookiebot?.consent?.statistics) {
      return
    }

    // already loaded
    if (window.hj) {
      return
    }

    const id = process.env['GATSBY_HOTJAR_ID']

    if (!id) {
      sentry.captureMessage('Hotjar ID not found')
      return
    }

    try {
      loadHotjar(parseInt(id))
    } catch (err) {
      console.log("Couldn't parse hotjar id to int:", id)
      sentry.captureException(err)
    }
  }

  sendHotjarEvent(testId: string, bucket: string): void {
    if (window?.hj) {
      window.hj('event', `ab-${testId}-group-${bucket}`)
    }
  }
}

export const hotjarService = new HotjarService()
