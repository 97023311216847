import classNames from 'classnames'
import { DatoCmsFileField } from 'dato-cms-assets'
import type { ReactNode } from 'react'
import styles from './MenuCategoryItem.module.scss'

export interface MenuArticle {
  text: string
  href: string
  thumbnail: DatoCmsFileField
  button?: {
    cta: string
    href: string
  }
}

export interface MenuCategory {
  category: string
  articles: MenuArticle[]
  cta?: string
  ctaLink?: string
}

export interface MenuButton {
  cta: string
  href: string
}
interface MenuCategoryItemProps {
  categoryData: MenuCategory
  isDesktop: boolean
  onSelect: () => void
  isSelected: boolean
  closeMenu: (skipAnimation?: boolean) => void
  onOpenModal: (categoryData: MenuCategory) => void
  modalCategoryData: MenuCategory | null
}

export function MenuCategoryItem({
  categoryData,
  isDesktop,
  onSelect,
  isSelected,
  closeMenu,
  onOpenModal,
  modalCategoryData,
}: MenuCategoryItemProps): ReactNode {
  function handleClick(): void {
    if (isDesktop) {
      onSelect()
    } else {
      // Close MenuLibrary without animation for after the modals is shown
      closeMenu(true)
      onOpenModal(categoryData)
    }
  }

  const isModalOpen = modalCategoryData === categoryData

  return (
    <button
      onClick={handleClick}
      className={classNames(styles.MenuCategoryItem__button, {
        [styles.MenuCategoryItem__button___selected]: isSelected,
      })}
      aria-controls={
        isDesktop
          ? 'desktop-articles-section'
          : `modal-${categoryData.category.replaceAll(/\s+/g, '-').toLowerCase()}`
      }
      {...(!isDesktop && {
        'aria-haspopup': 'dialog',
        'aria-expanded': isModalOpen,
      })}
    >
      <div className={styles.MenuCategoryItem__buttonContainer}>
        {categoryData.category}
        <img
          src="/icons/faq-chevron-black.svg"
          className={styles.MenuCategoryItem__chevron}
          aria-hidden="true"
          alt="Dropdown chevron"
        />
      </div>
    </button>
  )
}
