import { Button } from '@src/components/button/Button.component'
import { NcImage } from '@src/components/nc-image/NcImage.component'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { MenuCategory } from '../menu-category-item/MenuCategoryItem.component'
import styles from './MenuArticleModal.module.scss'

interface MenuArticleModalProps {
  categoryData: MenuCategory
  modalId?: string
  closeMenu?: () => void
  closeModal: () => void
  isDesktop?: boolean
}

export function MenuArticleModal({
  categoryData,
  closeMenu,
  closeModal,
  modalId,
  isDesktop,
}: MenuArticleModalProps): ReactNode {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        closeModal()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [closeModal])

  const modalContent = (
    <div
      className={styles.MenuArticleModal}
      role={isDesktop ? 'region' : 'dialog'}
      {...(!isDesktop && { 'aria-modal': 'true' })}
      aria-labelledby={`${modalId}-title`}
      onClick={e => e.target === e.currentTarget && closeModal()}
      id={isDesktop ? 'desktop-articles-section' : modalId}
    >
      <div className={styles.MenuArticleModal__content}>
        <div className={styles.MenuArticleModal__container}>
          <button
            className={styles.MenuArticleModal__close}
            onClick={closeModal}
            aria-label="Close filter"
          >
            <img aria-hidden={true} src="/icons/back-icon.svg" alt="close" />
          </button>
          <h2 id={`${modalId}-title`} className={styles.MenuArticleModal__name}>
            {isDesktop ? 'Featured Articles' : categoryData.category}
          </h2>
        </div>

        <ul className={styles.MenuArticleModal__articleContainer}>
          {categoryData.articles.map(article => (
            <li key={article.text}>
              <a
                href={article.href}
                className={styles.MenuArticleModal__article}
                onClick={closeMenu}
              >
                <NcImage
                  image={article.thumbnail.gatsbyImageData}
                  alt={article.thumbnail.alt}
                  className={styles.MenuArticleModal__articleImg}
                />
                {article.text}
              </a>
            </li>
          ))}
        </ul>

        {categoryData.cta && (
          <div className={styles.MenuArticleModal__button} onClick={closeMenu}>
            <Button
              href={categoryData.ctaLink}
              theme="ghost"
              size={isDesktop ? 'small' : 'flexible'}
            >
              {categoryData.cta}
            </Button>
          </div>
        )}
      </div>
    </div>
  )

  // Render modal using portal only for non-desktop
  return isDesktop ? modalContent : ReactDOM.createPortal(modalContent, document.body)
}
